import {usePage} from '@inertiajs/inertia-react';
import {mdiCheck, mdiChevronUp, mdiClose} from '@mdi/js';
import {Icon} from '@mdi/react';
import Tippy from '@tippyjs/react';
import cls from 'classnames';
import SlideTransition from 'Components/SlideTransition';
import TutorialSections from 'Components/Tutorial/FlattenedTutorialSections';
import _ from 'lodash';
import React, {useMemo, useState} from 'react';
import {useToggle} from 'react-use';
import useTutorialSectionUpdate from 'Support/Hooks/useTutorialSectionUpdate';
import useUser from 'Support/Hooks/useUser';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

const CheckListItem = ({item: {id, name, depth, completed, disabled}}) => {
  const [hovered, setHovered] = useState(false);
  const forgetSection = useTutorialSectionUpdate(id, false);

  return (
    <div
      className={cls('relative flex items-start gap-2 p-2 box-content', !!depth && 'border-l-2', completed && 'border-green-500', disabled && 'pointer-events-none')}
      style={{marginLeft: `${(depth * 1.25)}rem`}}>
      <Tippy content={`Forget ${name}?`} placement="right" theme="light-border">
        <button type="button" disabled={!completed} onClick={forgetSection} onMouseOver={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={cls('flex items-center gap-2 p-1 w-6 h-6 text-white outline-none focus:outline-none rounded', completed ? 'bg-green-500 hover:bg-red-500' : 'border-2 cursor-default')}>
          {completed && <Icon path={hovered ? mdiClose : mdiCheck}/>}
        </button>
      </Tippy>
      <span>{name}</span>
      {disabled &&
        <div className="absolute inset-1 flex items-center justify-center bg-gray-100/75 rounded font-semibold text-gray-700">
          Disabled
        </div>
      }
    </div>
  );
};

const TutorialChecklist = (props) => {
  const user = useUser();
  const [shown, toggleShown] = useToggle(true);

  const checkList = useMemo(() => (
    TutorialSections.reduce((acc, step) => (!Array.isArray(step?.userRoles) || user.isRole(...step.userRoles) ? [...acc, {
      ...step,
      completed: user?.tutorial_sections?.includes(step.id),
    }] : acc), [])), [user]);

  if (!usePage().props.application?.tutorial || !user.tutorial_active) return null;

  return (
    <div
      className={cls('fixed flex flex-col gap-1 w-80 p-5 bottom-12 left-10 bg-white z-50 rounded shadow-lg select-none', _.every(checkList, 'completed') && 'border-2 border-green-500')} {...props}>
      <SlideTransition show={shown} from="bottom">
        <div className="max-h-screen-1/2 overflow-y-auto">
          {checkList.map((step) => (
            <CheckListItem key={step.id} item={step}/>
          ))}
        </div>
      </SlideTransition>
      <div className="flex justify-between pt-1 text-sm border-t text-gray-700">
        <span>Tutorial Checklist</span>
        <button type="button" className="w-5 focus:outline-none" onClick={toggleShown}>
          <Icon path={mdiChevronUp} className={cls('transform duration-150', shown && 'rotate-180')}/>
        </button>
      </div>
    </div>
  );
};

export default TutorialChecklist;