import {Menu} from '@headlessui/react';
import {Link} from '@inertiajs/inertia-react';
import cls from 'classnames';
import React from 'react';

const MenuItem = ({children, href, as = Link, ...itemProps}) => {
  const ElementTag = as;

  const elementProps = {};
  if (typeof href !== 'undefined') {
    elementProps.href = href;
  }

  return <div className="py-1">
    <Menu.Item {...itemProps}>
      {({active}) => <ElementTag
        {...elementProps}
        className={cls({
          'bg-gray-100 text-gray-900': active,
          'text-gray-700': !active,
          'flex justify-between w-full px-4 py-2 text-sm leading-5 text-left': true,
        })}
      >
        {children}
      </ElementTag>}
    </Menu.Item>
  </div>;
};

export default MenuItem;