import {Inertia} from '@inertiajs/inertia';
import {AxiosError, AxiosRequestConfig} from 'axios';
import React, {useContext} from 'react';
import axios from 'Support/axios';
import FormErrorContext from 'Support/Contexts/FormErrorContext';

/**
 * @param {function({values})|null} [onStart]
 * @param {string} route
 * @param {AxiosRequestMethod} method
 * @param {boolean} [preserveScroll]
 * @param {boolean} [preserveState]
 * @param {boolean} [replace]
 * @param {string[]} [only]
 * @param {function({values})|null} [transformData]
 * @param {function({values})|null} [onSuccess]
 * @param {function({values})|null} [onFailure]
 * @param {AxiosRequestConfig} options
 * @returns {function(...[*]=)}
 */
const useInertiaSubmit = (
  {
    onStart = false,
    route,
    method = 'post',
    transformData,
    onSuccess,
    onFailure,
    replace = false,
    preserveScroll = false,
    preserveState = true,
    only = [],
    ...options
  },
) => {
  const {setErrors} = useContext(FormErrorContext);

  return async values => {
    try {
      if (typeof onStart === 'function') {
        onStart();
      }

      const visitId = Inertia.createVisitId();

      const response = await axios.request({
        ...options,
        method,
        url: route.toString(),
        headers: {
          'X-Inertia-Submit': true,
          'X-Inertia': true,
          'X-Requested-With': 'XMLHttpRequest',
          ...(only.length ? {
            'X-Inertia-Partial-Component': Inertia.page.component,
            'X-Inertia-Partial-Data': only.join(','),
          } : {}),
          ...(Inertia.page.version ? {'X-Inertia-Version': Inertia.page.version} : {}),
        },
        data: typeof transformData === 'function' ? transformData({...values}) : values,
      });

      const {data: responseData, headers} = response;

      if (headers['x-inertia']) {
        await Inertia.setPage(response.data, {visitId, replace, preserveScroll, preserveState});
      } else {
        setErrors({});
      }

      if (typeof onSuccess === 'function') {
        return await onSuccess(responseData);
      }
    } catch (/** @type {AxiosError} */ e) {
      if (e.response) {
        if (e.response.status === 422) {
          setErrors(e.response.data.errors);
          if (typeof onFailure === 'function') {
            return await onFailure(e);
          }
        }
      } else {
        if (typeof onFailure === 'function') {
          return await onFailure(e);
        } else {
          throw e;
        }
      }
    }
  };
};

export default useInertiaSubmit;