import {Switch} from '@headlessui/react';
import cls from 'classnames';
import React from 'react';

const defaultOuterClasses = 'relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring';
const enabledOuterClasses = 'bg-orange-400';
const disabledOuterClasses = 'bg-gray-200';

const defaultInnerClasses = 'inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full';
const enabledInnerClasses = 'translate-x-5';
const disabledInnerClasses = 'translate-x-0';

const ReactSwitch = ({yesText, noText,value,...props}) => {

  const outerClasses = cls(defaultOuterClasses, (value ? enabledOuterClasses : disabledOuterClasses));
  const innerClasses = cls(defaultInnerClasses, (value ? enabledInnerClasses : disabledInnerClasses));

  const label = (value && yesText) ? yesText : (!value && noText ? noText : false);

  return (
    <Switch.Group as="div" className="flex items-center space-x-4">
      <Switch as="button" checked={value} {...props} className={outerClasses}>
        <span className={innerClasses}/>
      </Switch>
      {label && <Switch.Label className="text-sm leading-5 font-medium text-gray-500">{label}</Switch.Label>}
    </Switch.Group>
  );
};

export default ReactSwitch;
