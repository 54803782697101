import cls from 'classnames';
import Ping from 'Components/Messages/Ping';
import React from 'react';
import {formatShortDatetime} from 'Support/valueFormatters';

const Message = ({body, pings, user, isCurrentUser, created_at}) => (
  <div className={cls('flex items-start gap-2 p-2 max-w-full animate-scale-in', isCurrentUser && 'self-end')}>
    <img src={user?.avatar} alt={`${user.name}'s Avatar`} className={cls('w-8 rounded-full', isCurrentUser && 'order-2')}/>
    <div className={cls('flex flex-col min-w-0', isCurrentUser ? 'items-end' : 'items-start')}>
      {!isCurrentUser && <div className="text-sm font-medium truncate text-gray-700">{user?.name}</div>}
      <div className={cls('p-2 max-w-full break-words rounded-lg', isCurrentUser ? 'bg-blue-500 text-white' : 'bg-gray-100')}>
        <div>{body}</div>
        {!!pings?.length && (
          <div className="flex flex-col p-1 gap-2 bg-white rounded">
            {pings.map(ping => (
              <Ping key={ping.id} ping={ping}/>
            ))}
          </div>
        )}
      </div>
      <div className="self-end text-xs text-gray-300">{formatShortDatetime(created_at)}</div>
    </div>
  </div>
);

export default Message;