import cls from 'classnames';
import React from 'react';

const ModalContentWrapper = ({children, onClose, title, subTitle = undefined, noScroll}) => (
  <div className="relative">
    <button onClick={onClose} type="button" className="absolute right-0 top-0 mr-3 mt-3 focus:outline-none">
      <i className="material-icons">close</i>
    </button>
    <div className="pt-6 max-h-screen-3/4 flex flex-col">
      <h3 className="text-lg px-6 leading-6 font-medium text-gray-900">
        {title}
      </h3>
      {subTitle && <p className="mt-1 px-6 text-sm leading-5 text-gray-500">{subTitle}</p>}

      <hr className="mx-6 mt-8 sm:mt-7 border-t border-gray-200"/>

      <div className={cls('px-6 pb-6', !noScroll && 'overflow-y-auto')}>
          {children}
      </div>
    </div>
  </div>
);

export default ModalContentWrapper;