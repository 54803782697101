import {App} from '@inertiajs/inertia-react';
import ActionOverlay from 'Components/Developer/ActionOverlay';

import 'intro.js/introjs.css';
import MainLayout from 'Layouts/MainLayout';
import React, {createElement} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {render} from 'react-dom';
import {createRoot} from 'react-dom/client';
import Modal from 'react-modal';
import {ModalProvider} from 'react-modal-hook';
import {ToastContainer} from 'react-toastify';
import 'Style/app.css';
import {FormErrorContextProvider} from 'Support/Contexts/FormErrorContext';

const app = document.getElementById('app');

// Accessibility Requirement http://reactcommunity.org/react-modal/accessibility/#app-element
Modal.setAppElement(app);

createRoot(app).render(
  <App
    initialPage={inertiaInitialPageData}
    resolveComponent={async name => {
      const comps = import.meta.glob('./Pages/**/*.{tsx,jsx}');
      const match = comps[`./Pages/${name}.tsx`] ?? comps[`./Pages/${name}.jsx`];
      if (!match) {
        throw new Error(`You've attempted to load the Inertia.js page 'Pages/${name}.{tsx,jsx}', but no file with that name exists.`);
      }

      return (await match()).default;
    }}>

    {({key, props, Component}) => {
      const page = createElement(Component, {key, ...props});
      let children;

      if (typeof Component.layout === 'function') {
        children = Component.layout(page);
      } else if (Array.isArray(Component.layout)) {
        children = Component.layout.concat(page).reverse().reduce((children, Layout) => createElement(Layout, {children}));
      } else {
        children = <MainLayout children={page}/>;
      }

      return (
        <FormErrorContextProvider>
          <DndProvider backend={HTML5Backend}>
            <ModalProvider>
              {children}
              <ToastContainer autoClose={500000} pauseOnFocusLoss pauseOnHover position="bottom-right"/>
              {!!props?.application?.settings?.['dev.overlays'] &&
                <ActionOverlay
                  openKey={props?.application?.settings?.['dev.inertia_nav_key']}
                  backdrop={props?.application?.settings?.['dev.overlay_backdrops']}
                />
              }
            </ModalProvider>
          </DndProvider>
        </FormErrorContextProvider>
      );
    }}
  </App>
);